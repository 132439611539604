
import { defineComponent } from "vue";

import NavMenuDashboard from "@/components/molecules/NavMenuDashboard.vue";
import Logo from "@/assets/svg/logo-dashboard.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import { WalletDisconnectButton } from "@/plugins/solana-wallets-vue";
import NavMenuMob from "@/components/molecules/dashboard/NavMenuMob.vue";
import MenuIcon from "@/assets/svg/menu-icon.svg?inline";
import * as web3 from "@solana/web3.js";
import { useWallet } from "@/plugins/solana-wallets-vue";

export default defineComponent({
  name: "HeaderDashboard",
  data() {
    return {
      data: {
        logOut: "Log out",
      },
      mobileMenu: false,
      balance: 0,
      accountMHT: null,
    };
  },
  components: {
    NavMenuDashboard,
    Logo,
    MainButton,
    WalletDisconnectButton,
    NavMenuMob,
    MenuIcon,
  },
  mounted() {
    this.getBalance();
  },
  computed: {
    balanceMHT() {
      return String(this.balance.uiAmountString).replace(
        /(\d)(?=(\d{3})+([^\d]|$))/g,
        "$1 "
      );
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("phantom-token");
      localStorage.removeItem("user");
      this.$router.push("/");
    },
    openMobMenu() {
      this.mobileMenu = true;
    },
    closeMobMenu() {
      this.mobileMenu = false;
    },
    async getBalance() {
      var connection = new web3.Connection(
        web3.clusterApiUrl("devnet"),
        "confirmed"
      );
      // MHT token Account 4jrfACuxpF5ihJj3WXUpvXqEYYNa3Hqqjv6a5frb4gLk
      // MHT token mint 5JXeASyanwK9BjKWLcQduUELfVgPwH6Lk4A9ywZ2pEHY
      const { publicKey } = useWallet();
      if (publicKey.value) {
        const accountPublicKey = new web3.PublicKey(publicKey.value.toBase58());
        const mintAccount = new web3.PublicKey(
          "5JXeASyanwK9BjKWLcQduUELfVgPwH6Lk4A9ywZ2pEHY"
        );
        let TokenAccounts = await connection.getTokenAccountsByOwner(accountPublicKey, {
          mint: mintAccount,
        });
        for (let i = 0; i < TokenAccounts.value.length; i++) {
          this.accountMHT = TokenAccounts.value[i].pubkey.toBase58();
        }
        let TokenLargestAccounts = await connection.getTokenLargestAccounts(mintAccount);
        for (let i = 0; i < TokenLargestAccounts.value.length; i++) {
          if (this.accountMHT == TokenLargestAccounts.value[i].address.toBase58()) {
            this.accountMHT = TokenLargestAccounts.value[i].address.toBase58();
          }
        }
        if (publicKey.value && this.accountMHT !== null) {
          this.balance = await (
            await connection.getTokenAccountBalance(
              new web3.PublicKey(this.accountMHT)
            )
          ).value;
        }
      }
    },
  },
});
