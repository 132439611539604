import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-262908c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-menu-dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/mint"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.mint), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/my-collection"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.myCollection), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/catalog"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.catalog), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/my-mht"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.myMht), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/get-mht"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.getMht), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/profile"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.profile), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "nav-menu-dashboard__link",
      to: "/dashboard/my-favorite"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.data.myFavorite), 1)
      ]),
      _: 1
    })
  ]))
}