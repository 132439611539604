
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavMenuDashboard",
  data() {
    return {
      data: {
        mint: "Mint",
        myCollection: "My collection",
        catalog: "Catalog",
        myMht: "My MHT",
        getMht: "Get MHT",
        profile: "Profile",
        myFavorite: "My favorite",
      },
    };
  },
  components: {},
  mounted() {},
  methods: {},
});
